// Import Libraries
import Cookies from 'js-cookie';
import { builderDispatch, WebUI } from '../_utils/ui';

const MESSAGE_PREFIX = /^login:/;
const LOGGED_IN_COOKIE_NAME = 'web-kiosk-logged-in';

const unableToUsePostMessage = (function() {
  if (navigator.appName === 'Microsoft Internet Explorer') {
    const ua = navigator.userAgent;
    const re = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})');
    return re.exec(ua) !== null;
  }

  if (navigator.appName === 'Netscape') {
    const ua = navigator.userAgent;
    const re = new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})');
    return re.exec(ua) !== null;
  }
})();

if (window.opener) {
  // in the launchED page
  window.onLoggedIn = function(props) {
    if (unableToUsePostMessage) {
      Cookies.set(LOGGED_IN_COOKIE_NAME, props);

      const objWin = window.self;
      objWin.open('', '_self', '');
      objWin.close();
    } else {
      window.opener.postMessage(`login:${props}`, '*');
      window.close();
    }
  };
}

if (!window.opener) {
  // in the launchER page
  if (unableToUsePostMessage) {
    cookieListener();
  } else {
    window.addEventListener('message', messageHandler, false);
  }
}

function handleLoggedIn(message) {
  // in the launcher page
  const props = JSON.parse(message.replace(MESSAGE_PREFIX, ''));

  if (props.logged_in_user_name || props.login_path) {
    App.order.setProperties(props);

    if (props.csrf) {
      // Update the CSRF token meta tag with new value as the session has been reset without page-reload
      // so the current token is invalid
      document
        .querySelector('[name="csrf-token"]')
        .setAttribute('content', props.csrf);
      $.rails.refreshCSRFTokens();
    }

    builderDispatch.dispatch({
      type: WebUI.LOGIN,
      basketPath: props.basket_path,
      prodigal: props.prodigal,
      previous_basket_contents: props.previous_basket_contents,
      wmb_user_account_incomplete: props.wmb_user_account_incomplete,
    });
  }
}

function cookieListener() {
  // poll to see if the other end has posted the cookie
  Cookies.remove(LOGGED_IN_COOKIE_NAME);
  const tmr = window.setInterval(checkForLogin, 500);

  function checkForLogin() {
    const message = Cookies.get(LOGGED_IN_COOKIE_NAME);
    if (message) {
      handleLoggedIn(message);
      window.clearTimeout(tmr);
    }
  }
}

function messageHandler(event) {
  const ORIGINS = [
    `${window.location.origin}`.replace(/^http:/, 'https:'),
    `${window.location.origin}`.replace(/^https:/, 'http:'),
  ];

  const message = event.data;

  if (isLoginMessage()) {
    handleLoggedIn(message);
  }

  function isLoginMessage() {
    return (
      ORIGINS.indexOf(event.origin) > -1 &&
      typeof message === 'string' &&
      MESSAGE_PREFIX.test(message)
    );
  }
}
